.x-project {
  padding: 2em 0;
  section {
    .x-pagination {
      font-size: 1.2em;
      color: #828282;
      @include mobile() {
        font-size: 1.1em;
      }
    }
    .x-pagination-box{
      display: flex;
      justify-content: space-between;
    }
    .x-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #eee;
      padding: 2em 0;
      @include mobile(){
        display: block;
        .x-title{
          margin-bottom: .5em;
        }
          .ui.huge.button{
          padding: .8em .8em !important;
          font-size: .9em !important;
          text-transform: lowercase !important;
          margin-top: .2em;
      }
      }
    }
    .x-title {
      font-size: 2.2em;
      color: $blue;
      text-transform: capitalize;
      @include mobile() {
        font-size: 1.3em;
      }
    }
    .x-project {
      font-size: 1.2em;
      margin: 1em 0;
    }
  }

  .x-first-sec {
    display: flex;
    padding: 1em 0;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    .x-back span {
      font-size: 1.2em;
    }
    .x-date {
      color: #828282;
    }
  }
  .x-second {
    background: #fff;
    .x-head {
      border-bottom: 1px solid #b5e0ff;
      ul {
        display: flex;
        list-style-type: none;
        width: 100%;
        margin: 0;
        background: #e1f3ff;
        padding: 0 1em;
        li {
          padding: 1em 2em;
          font-size: 1.2em;
          cursor: pointer;
         
          &:hover {
            border-bottom: 4px solid $solidDash;
            transition: 0.2s ease-in;
          }
          &.active {
            color: $blue;
            font-family: Avenir-Bold;
            border-bottom: 4px solid $solidDash;
            transition: 0.2s ease-in;
          }
        }
      }
    }
    .x-body {
      margin-top: 3em;
      padding: 1.5em 0;
      font-size: 1.1em;
      .x-left {
        padding: 1em 2em;
        text-transform: capitalize;
        img {
          width: 12px;
          margin-right: 0.5em;
        }
        button {
          border-radius: 20px !important;
          background: #00b9ff !important;
          i {
            color: #fff !important;
          }
        }
      }
      .x-box {
        background: #f8fcff;
        p {
          padding: 1.5em 2em 0;
          font-size: 1.1em;
        }
        .x-desc {
          padding: 0 2em;
          margin-bottom: 1em;
        }
      }
    }
  }
  .x-third {
    background: #fff;
    margin-top: 2em;

    .x-head {
      border-bottom: 1px solid #b5e0ff;
      overflow-y: auto;
      ul {
        display: flex;
        list-style-type: none;
        width: 100%;
        margin: 0;
        background: #f8fcff;
        padding: 0 1em;
        li {
          padding: 1em 2em;
          font-size: 1.2em;
          cursor: pointer;
          color: #37517e;
          @include mobile(){
            font-size: 1.1em;
          }
          &:hover {
            border-bottom: 4px solid $solidDash;
            transition: 0.2s ease-in;
          }
          &.active {
            color: $blue;
            font-family: Avenir-Bold;
            border-bottom: 4px solid $solidDash;
            transition: 0.2s ease-in;
          }
        }
      }
    }
    .x-body {
      margin-top: 0em;
      font-size: 1.1em;
      .x-subtitle {
        border-bottom: 1px solid #e0e0e0;
        padding: 2em;
        font-size: 1.3em;
        color: #828282;
      }
      .x-left {
        padding: 1em 2em;
        text-transform: capitalize;
        @include mobile(){
          padding: 0 1em;
      }
        img {
          width: 12px;
          margin-right: 0.5em;
        }
      }
      .x-box {
        background: #f8fcff;
        p {
          padding: 1.5em 2em 0;
          font-size: 1.1em;
        }
        .x-desc {
          padding: 0 2em;
          margin-bottom: 1em;
        }
      }
    }
  }
  #photosphere {
    width: 100%;
    height: 400px;
    @include mobile(){
      height: 300px;
  }
  }
}
