.x-explore {
  padding: 2em 0;
  section {
    .x-pagination {
      font-size: 1.2em;
      color: #828282;
    }
    .x-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #eee;
      padding: 2em 0;
    }
    .x-title {
      font-size: 2.2em;
      color: $blue;
      text-transform: capitalize;
    }
    .x-project {
      font-size: 1.2em;
      margin: 1em 0;
    }
  }

  .x-first-sec {
    display: flex;
    padding: 1em 0;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    .x-back span {
      font-size: 1.2em;
    }
    .x-date {
      color: #828282;
    }
  }
  .x-filter-tab {
    margin-left: 1em;
    background: #fff;
    .x-head {
      display: flex;
      background: #e1f3ff;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #b5e0ff;
      padding: 1.3em;
      .x-filter {
        span {
          color: $blue;
          font-family: Avenir-Medium;
          cursor: pointer;
          // font-size: 1em;
        }
      }
      .x-clear {
        text-decoration: underline;
        color: $blue;
      }
    }
    .x-heading {
      font-family: Avenir-Medium;
      font-size: 1.1em;
      padding: 1em 1em 0;
    }
    .x-list {
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        li {
          padding: 1.2em;
          background: #f7f7f7;
          margin: 0.1em 1em;
          label {
            font-size: 1.1em;
          }
          .ui.checkbox {
            display: block;
          }
          &:hover {
            background: $blue;
            label {
              color: #fff;
            }
          }
        }
      }
    }
    .horizontal-slider {
      display: flex;
      align-items: center;
      border:  2px solid #e1f3ff;
      margin: 1em ;
      .example-track.example-track-0 , .example-track.example-track-2 {
        background: $blue;
      color: #fff;
      height: 3px;
      }
      .example-thumb.example-thumb-0  , .example-thumb.example-thumb-1 {
      background: $blue;
      border-radius: 2px;
      min-width: 30px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 0.8em;
      }
    }
    .x-last {
      text-align: center;
      padding: 1em;
    }
  }
  .x-second {
    background: #fff;
    .x-head {
      border-bottom: 1px solid #b5e0ff;
      ul {
        display: flex;
        list-style-type: none;
        width: 100%;
        margin: 0;
        background: #e1f3ff;
        padding: 0 1em;
        li {
          padding: 1em 2em;
          font-size: 1.2em;
          cursor: pointer;
          &:hover {
            border-bottom: 4px solid $solidDash;
            transition: 0.2s ease-in;
          }
          &.active {
            color: $blue;
            font-family: Avenir-Bold;
            border-bottom: 4px solid $solidDash;
            transition: 0.2s ease-in;
          }
          &:last-of-type {
            margin-left: auto;
            padding-right: 0;
            color: $blue;
          }
        }
      }
    }
    .x-body {
      margin-top: 1em;
      padding: 1.5em 0;
      font-size: 1.1em;
      .x-filter {
        display: flex;
        margin: 0 1em 2em;
        justify-content: flex-end;
        text-decoration: underline;
        span {
          color: $blue;
          font-family: Avenir-Medium;
          cursor: pointer;
          // font-size: 1em;
        }
      }
      .x-left {
        padding: 1em 2em;
        text-transform: capitalize;
        img {
          width: 12px;
          margin-right: 0.5em;
        }
        button {
          border-radius: 20px !important;
          background: #00b9ff !important;
          i {
            color: #fff !important;
          }
        }
      }
      .x-box {
        background: #f8fcff;
        p {
          padding: 1.5em 2em 0;
          font-size: 1.1em;
        }
        .x-desc {
          padding: 0 2em;
          margin-bottom: 1em;
        }
      }
      .x-card {
        .x-img {
          height: 200px;
          img {
            width: 100%;
            height: 200px;
            object-fit: cover;
          }
        }
        .x-features {
          border-bottom: 4px solid $lightblue;
          display: inline-block;
          margin: 0 0 1em;
          color: $blue;
          font-family: Avenir-Medium;
        }
        h3 {
          margin: 1em 0;
        }
        .text {
          margin-bottom: 0.2em;
        }
        button {
          margin-top: 1em;
        }
      }
    }
  }
  .x-third {
  }
}
