.ticketChat{
    // background: #fff;
    // position: fixed;
    // right: 0;
    // bottom: 0;
    // width: 30%;
    // height: 80%;
    // background: #fff;
    // z-index: 1023;
    
    .allUsersList{
        width: 300px;
        margin: 20px;
      }
      .allUsersList .card-header{
        background: #0c6fb8;
        color: #FFF;
        padding: 10px;
      }
      .allUsersList .image img{
        border-radius: 16px;
      }
      .usersChatList{
        position: absolute;
        width: 250px;
        bottom: 0;
        margin-bottom: 0;
        right: 360px;
      }
      .show{
        display: block;
      }
      .thumb-user-list{
        display: none;
      }
      .thumb-user-list .image img{
        border-radius: 30px;
      }
      .usersChatList .card-header{
        background: #0c6fb8;
        font-size: 13px;
       
      }
      .chatBox{
        position: fixed;
        bottom: 0;
        right: 0;
        width: 300px;
        margin: 40px;
        margin-bottom: 0;
        font-size: 13px;
        background: #fff;
        border: 1px solid #eee;
        z-index: 102400;
      }
      .chat-content{
        overflow: auto;
        height: 300px;
        padding: 0 1em;
      }
      .chatBox .card{
        border-radius: 4px;
      }
      .chatBox .card-header{
        background: #0c6fb8;
        // &.success{
        //   background: $blue;
        // }
      }
      .header-title{
        height: 50px;
        justify-content: space-between;
        padding: 1em;
        align-items: center;
        display: flex;
        .resolved{
          text-decoration: underline;
        color: #fff;
          margin-right: 0.5em;
          cursor: pointer;
        }
      }
      .card-header-title i{
        font-size: 10px;
        color: #32e4cd;
        margin-right: 6px;
      }
      .card-header .card-header-title{
        color: #FFF;
        
      }
      .chat-content small{
        margin: 0;
      }
      .chat-content p{
        background: #ecf1f8;
        padding: 10px;
        border-radius: 8px;
        margin-bottom: 0;
      }
      .my-content .media-content{
        width: 80%;
      }
      .my-content .message{
        float: right;
        background: #0c6fb8;
        color: #FFF;
        text-align: right;
        padding: 10px;
        margin-bottom: 4px;
        font-size: 13px;
      }
      .my-content .chat-content small{
        float: right;
      }
      .my-content small{
        display: block;
        float: right;
        width: 100%;
        text-align: right;
      }
      .chat-textarea{
        font-size: 14px;
        padding: 8px;
        height: 40px;
        width: 100%;
        border: none;
        overflow: auto;
        outline: none;
      
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        resize: none;
      }
      .chat-message-group{
        display: flex;
        &.x-issue{
          width: 100%;
          .chat-messages{
            float: none;
            width: 100%;
            .message{
              background: unset;
              float: none;
              text-align: center;
            }
          }
        }
      }
      .chat-message-group .chat-thumb{
        float: left;
        width: 15%;
      }
      .chat-message-group .chat-messages{
        float: left;
        width: 85%;
        margin-bottom: 20px;
        .subject{
            width: 80%;
            cursor: pointer;
            content: "";
  display: table;
  clear: both;
            img{
            width: 100%;
          }
          .preview{
            background: #ecf1f8;
            font-size: 0.9em;
            margin-top: -.5em;
            padding: .5em;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            font-weight: bold;

            div{
              &:first-letter {
                text-transform: uppercase;
              }
            }
            span{
              font-size: 0.9em;
            }
          }
        }
      }
      .chat-message-group .message{
        float: left;
        padding: 10px;
        background: #ecf1f8;
        font-size: 13px;
        border-radius: 5px;
        margin-bottom: 3px;
      }
      .chat-messages .from{
        float: left;
        display: block;
        width: 100%;
        text-align: left;
        font-size: 11px;
        
      }
      .chat-thumb img{
        border-radius: 40px;
      }
      .writer-user .chat-messages{
        float: right;
        width: 100%;
      }
      .writer-user .chat-messages .message{
        float: right;
        background: #0c6fb8;
        color: #FFF;
      }
      .writer-user .chat-messages .from{
        float: left;
        display: block;
        width: 100%;
        text-align: right;
      }
      .chat-message-group .typing{
        float: left;
      }
      .chatBox .card-header-icon {
        color: #FFF;
        font-size: 13px;
        // position: absolute;
        right: 10px;
        cursor: pointer;
      }
      /* CSSS */
      .outside-box{
        height: 100px;
        background: #F8C;
        width: 200px;
        margin: 20px;
        overflow: auto;
      }
      .outside-box .content-insider{
        height: 300px;
        background: #C9C;
      }
      /* CSS Spinner */
      .spinner {
        margin: 0 30px;
        width: 70px;
        text-align: center;
      }
      
      .spinner > div {
        width: 4px;
        height: 4px;
        background-color: #888;
      
        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      }
      
      .spinner .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
      }
      
      .spinner .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
      }
      
      @-webkit-keyframes sk-bouncedelay {
        0%, 80%, 100% { -webkit-transform: scale(0) }
        40% { -webkit-transform: scale(1.0) }
      }
      
      @keyframes sk-bouncedelay {
        0%, 80%, 100% { 
          -webkit-transform: scale(0);
          transform: scale(0);
        } 40% { 
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
        }
      }
      /* EmojiBox */
      .emojiBox{
        width: 150px;
        margin: 30px;
      }
      .emojiBox .box{
        height: 100px;
        padding: 4px;
      }
      /* */
      .card-header-title img{
        border-radius: 40px;
      }
      .card-footer{
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-top: 1px solid #eee;
          textarea{
              padding-left: 1em;
          }
      }
}