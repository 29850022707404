.x-template {
  padding: 2em 0;
  section {
    .x-pagination {
      font-size: 1.2em;
      color: #828282;
    }
    .x-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #eee;
      padding: 2em 0;
    }
    .x-title {
      font-size: 2.2em;
      color: $blue;
      text-transform: capitalize;
    }
    .x-project {
      font-size: 1.2em;
      margin: 1em 0;
    }
  }

  .x-first-sec {
    display: flex;
    padding: 1em 0;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    .x-back span {
      font-size: 1.2em;
    }
    .x-date {
      color: #828282;
    }
  }
  .x-second {
    background: #fff;
    .x-head {
      border-bottom: 1px solid #b5e0ff;
      ul {
        display: flex;
        list-style-type: none;
        width: 100%;
        margin: 0;
        background: #e1f3ff;
        padding: 0 1em;
        li {
          padding: 1em 2em;
          font-size: 1.2em;
          cursor: pointer;
          &:hover {
            border-bottom: 4px solid $solidDash;
            transition: 0.2s ease-in;
          }
          &.active {
            color: $blue;
            font-family: Avenir-Bold;
            border-bottom: 4px solid $solidDash;
            transition: 0.2s ease-in;
          }
        
        }
      }
    }
    .x-body {
      margin-top: 1em;
      padding: 1.5em 0;
      font-size: 1.1em;
      .x-title{
        margin-bottom: 1em;
        padding: 0 2em 2em;
        border-bottom: 2px solid #eee;
      }
      .x-left{
        padding:  2em;
        font-size: 1.1em;
        h4{
          font-size: 1.1em;
        }
        .text{
          margin-bottom: 0.5em;
        }
      }
      .x-right{
        border-left: 2px solid #eee;
        padding:  2em;
      }
      .slick-slider {
        img{
          width: 100%;
        }
        .slick-slide{
          height: 300px;
          // overflow: hidden;
         
        }
        .slick-dots{
         position: unset;
         margin-top: 1em;

          li{
            width: 50px;
            margin: 1em;
          }
        }
       
       
      }
    }
  }
  .x-third {
  }
}
