.x-projectUpdates {
  min-height: 400px;
  .ui.table {
    border: unset;
  }
  .ui.table thead th {
    background: #fff;
    padding: 2em;
    font-family: Avenir-Medium;
    border: unset;
  }
  .ui.table .row {
    cursor: pointer !important;
  }
  .ui.striped.table tbody tr:nth-child(2n),
  .ui.striped.table > tr:nth-child(2n) {
    background: #f8fcff;
  }
  .ui.table td {
    padding-top: 2em;
    padding-bottom: 2em;
    button {
      border-radius: 20px !important;
    }
  }
}
.x-projectcard2 {
  .x-card {
    background: #fff;

    .x-topcard {
      padding: 2em 1.8em;

      h2 {
        color: $blue;
        margin: 0;
        font-size: 1.5em;
      }
      i {
        color: $blue;
      }
      img {
        width: 15px;
        margin-right: 1em;
      }
      .x-landSize,
      .x-location,
      .x-tour {
        margin: 1.5em 0 0em;
        p {
          font-size: 1.1em;
        }
        button {
          border-radius: 20px !important;
          background: #00b9ff !important;
          i {
            color: #fff !important;
          }
        }
      }
    }
    .x-boxcard {
      background: #f8fcff;
      border: 1px solid #b5e0ff;
      margin: 0 0.5em 0em;

      .x-stats {
        border-bottom: 0.5px solid #b5e0ff;
        padding: 1em 1.5em;
        align-items: center;
        .title {
          font-size: 1.2em;
          font-family: Avenir-Medium;
        }
        button {
          border-radius: 20px !important;
        }
      }
      .x-projectStatusDesc {
        padding: 2em 0.5em;
      }
    }
  }
}

.x-projectimages {
  min-height: 400px;
  background: #fff;

  margin-top: 1em;
  padding: 1.5em 0;
  font-size: 1.1em;
  .x-boxcard{
    // padding: .5em;
    cursor: pointer;
    position: relative;
    &.x-add {
      border: 1px dashed #57709b;
      border-radius: 5px;
      // padding-top: 1.5em;
    }
    :hover{
      img{
        transition: 0.2s ease-in;
        width: 110%;
        height: 110%;
        margin-left: -5%;
        margin-top: -5%;

        // margin-top: -3%;
      }
    }
    .x-shadow{
      // background-color: rgba(0, 0, 0, 0.1);
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(2, 2, 2, 0.4));
      position: absolute;
      height: 130px;
      right: 0;
      left: 0;
    }
   
    .img-box{
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    height: 130px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
    img{
      width: 100%;
      height: 100%;
        transition: 0.2s ease-in;
        object-fit: cover;
        object-position: center;
      
    }
    .x-text{
      padding: .3em;
      font-size: .75em;
      color: #37517E;
      line-height: normal;
    }
    span{
      margin: 0 5px
    }
    i{
      font-size: 3em;
      margin: 0;
      color: #57709b;
      margin-top: .4em;
      
    }
  }
  .x-title {
    margin-bottom: 1em;
    padding: 0 2em 2em;
    border-bottom: 2px solid #eee;
  }
  .x-left {
    padding: 1em 2em;

    h4 {
      font-size: 1.1em;
      @include mobile() {
        font-size: 0.9em;
      }
    }
    .text {
      margin-bottom: 0.5em;
    }
  }
  .x-right {
    border-left: 2px solid #eee;
    padding: 1em 2em;
    @include mobile(){
        padding: 0;
    }
  }
  .slick-slider {
    img {
      width: 100%;
    }
    video {
      width: 100%;
    }
    .slick-slide {
      //   height: 300px;
      // overflow: hidden;
    }
    .slick-dots {
      position: unset;
      margin-top: 1em;

      li {
        width: 50px;
        margin: 1em;
      }
    }
  }
}

.x-projectViewModal{
  height: 80%;
  &.ui.fullscreen.modal{
    left: unset !important;
  }
  #photosphere{
    width: 100%;
    height: 100%;
  }
  .ui.grid , .column{
  margin: 0 !important;
    padding: 0 !important;
    height: 100%;
  }
  .x-left-grid{
    background-color: #142334;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    img{
      object-fit: contain;
      height: 100% !important;
      width: 100%;
    }
    video{
      width: 100%;
      height: 100% !important;
    }

  .x-btn-div{
    display: flex;
    justify-content: space-between;
    position: absolute;
    z-index: 1024;
    width: 100%;
    padding: 0 1em;
    button{
      border-radius: 100% !important;
      background: rgba(211, 211, 211, 0.8);
    }
  }
  }
  .x-right-grid{
    padding: 2em;
  }
  // .x-btn-round{
  //   width: 50px;
  //   height: 50px;
  //   border-radius: 100%;
  //   background-color: #eee;
  //   font-size: 1.5em;
  //   display: flex ;
  //   justify-content: center;
  //   align-items: center;

  // }
}

