
.signin 
{

.left-grid{
    position: fixed;
    left:0;
    height:-webkit-fill-available;
    overflow: auto;
    bottom: 0;
    width: inherit;
    color: #fff;
    z-index: 100;
    top:0;
    background: #000;
    padding: 30px 100px 10px
}
 label{
    color: #fff !important
}
 .left-grid{
    color: #fff;
    position: fixed;
    height:-webkit-fill-available;
    width: inherit;
    /* left:0; */
    bottom: 0;
    background: url("../../assets/signupimg.png");
    padding: 100px 100px 
}

 a{
    color: #000;
    text-decoration: underline;
    font-weight: bolder !important
}
 .right-grid{
    padding: 8em 5em;
    @include mobile(){
        padding: 4em 1.5em;
    }
    .option{
        border: 1px solid #B5E0FF ;
        background: #E1F3FF;
        padding: 2em ;
        margin: 1em 0;
        cursor: pointer;
        p{
            font-size: 1.2em;
            color: #4F4F4F;
        }
    }

}
 .right-grid h2{
    /* font-size: 2.3em; */
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 29px;
margin: 1em 0 .2em;
/* identical to box height */

letter-spacing: 0.015em;
    color:#37517E !important
}
 .right-grid img{
    width: 100px;
}
 .right-grid  {
    font-size: 1.1em;
}
 .subtitle {
    color: #828282;
    font-size: 18px ;
    margin: .5em 0;
}
 input,  .ui.selection.dropdown,  textarea{
    background: #fff !important;
    color: #37517E !important; 
    border: 1px solid #37517E !important;
    border-radius: unset !important;
    font-size: 0.8em !important;
    padding-top: 1.2em !important;
    padding-bottom: 1.2em !important;
    &:focus{
        border: 1px solid #00B9FF !important;
    }
}
 input:focus,.ui.selection.dropdown:focus, textarea:focus{
    /* outline: unset !important; */
    /* box-shadow: unset !important; */
    /* border: unset !important */
}
 .ui.horizontal.divider{
    /* color: #fff; */
    text-transform: unset;
    font-weight: bold;
    padding: 1em 0 !important;
}
 form{
    /* text-align: center; */
    /* padding: 100px 30px 10px */
}
.admin form{
    padding:unset !important

}
 .ui.fluid.selection.dropdown,.visible.menu.transition{
    border-top: unset !important;
    border-left: unset !important;
    border-right: unset !important;
}
 .bottom-text{
    margin: 2em 0 0 !important;
    text-align: center;
    border-top: 0.5px solid #C4C4C4;

}


}
.reg-success{
    // background: url("../../assets/signupimg.png");
    // position: absolute;
    // width: 100%;
    // height: 100%;
    // background-size: cover;
    padding: 3em 0;
    .x-card{
        background: $white;
        width: 80%;
        margin: auto ;
        border-radius: 10px;
        padding: 4em 2em;
        text-align: center;
        
        img{
            width: 50%;
        }
         h2{
            font-family: Avenir-Bold;
            color: #37517E;
            font-size: 3em;
        }
        p{
            font-size: 1.3em;
            color: #828282;
        }
        p.x-medium{
            color: #000;
        }
        button{
            width: 50% !important;
        }
    }

}