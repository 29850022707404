.x-home {
  section{
    width: 100%;
    overflow: hidden;
  }
  .x-first-section {
    .x-left {
      padding: 6em 3em;
      @include mobile() {
        padding: 3em 1.5em;
      }
      .x-pretext {
        color: $blue;
        font-family: Avenir-Medium;
      }
      h1 {
        font-family: Avenir-Bold;
        font-size: 3em;
        @include mobile() {
          font-size: 2em;
        }
      }
      .x-description {
        font-size: 1.1em;
        margin-bottom: 2em;
      }
      button {
        border-radius: 5px !important;
      }
    }
    .x-right-img {
      background: url("../../assets/desktop.png") no-repeat;
      background-size: cover;
      width: 100%;
      height: 500px;
    }
  }
  .x-second {
    padding: 5em 3em;
    @include mobile() {
      padding: 2em 1.5em;
    }
    .x-left {
      width: 70%;
      @include mobile() {
        width: 100%;
      }
      h2 {
        color: $blue;
        font-size: 2.5em;
        @include mobile() {
          font-size: 1.5em;
        }
      }
    }
    .x-right {
      font-size: 1.1em;
      padding: 0 1em;
      @include mobile() {
        padding: 1em 0;
      }
    }

    .x-works {
      text-align: center;
      margin: 5em 0 1em;
      @include mobile() {
        margin: 2em 0 0;
        h2 {
          font-size: 1.5em;
          margin: 0;
        }
      }
      .x-pretext {
        color: $blue;
        font-family: Avenir-Medium;
      }
    }
    .x-boxes {
      .x-box {
        padding: 2em;
        @include mobile() {
          padding: 0;
          margin-bottom: 2em;
        }
        a {
          font-family: Avenir-Medium;
          color: $blue;
          border-bottom: 2px solid #1fbaec;
        }
        img {
          margin: 1em 0;
          width: 100%;
          @include mobile() {
            margin: 1em 0 0;
          }
        }
      }
    }
    .x-trust {
      text-align: center;
      margin: 3em 0 1em;
      b {
        font-family: Avenir-Medium;
      }
      span {
        color: grey;
      }
      @include mobile() {
        margin: 0;
      }
      .x-pretext {
        color: $blue;
        font-family: Avenir-Medium;
      }
      img {
        width: 50px;
        margin: 0.5em;
        &.active {
          width: 70px;
        }
      }
    }
  }
  .x-third {
    .x-box {
      margin: 2em;
      text-align: center;
      @include mobile() {
        margin: 0 1.5em;
      }
      h3 {
        padding: 4em 1em 0;
      }
      p {
        padding: 0em 4em 4em;
      }
      &.blue {
        background: #bdf3fc;
        color: #223c54;
      }
      &.darkblue {
        background: #182d3f;
        color: #fff;
      }
      &.lightblue {
        background: #0a6fb7;
        color: #fff;
      }
      img {
        width: 100%;
        margin-bottom: -0.4em;
        //   height: 300px;
      }
    }
  }
  .x-download {
    .x-left {
      padding: 8em 5em;
      @include mobile() {
        padding: 0em 1.5em;
      }
      .x-pretext {
        color: $blue;
        font-family: Avenir-Medium;
      }
      h2 {
        font-size: 2.5em;
        @include mobile() {
          font-size: 2em;
        }
      }
      p {
        font-size: 1.1em;
      }
      img {
        width: 130px;
        margin-right: 1em;
        @include mobile() {
          width: 100px;
        }
      }
    }
    .x-mobile-img {
      background: url("../../assets/mobile.png") no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 100%;
    }
  }
  .x-software {
    .x-right {
      padding: 8em 5em;
      @include mobile() {
        padding: 2em 1.5em;
      }
      .x-pretext {
        color: $blue;
        font-family: Avenir-Medium;
      }
      h2 {
        font-size: 2.5em;
        @include mobile() {
          font-size: 2em;
        }
      }
      p {
        font-size: 1.1em;
      }
      img {
        width: 130px;
        margin-right: 1em;
      }
    }
    .x-mobile-img {
      background: url("../../assets/dashboard.png") no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 100%;
    }
  }
  .x-trust {
    margin: 2em;
    .x-eng {
      text-align: center;
      margin: 5em;
      @include mobile() {
        text-align: left;
        margin: 0 1em 2em;
       
      }
    }
    
    @include mobile() {
      margin: 0 0.5em 2em;
    h3{
        margin-bottom: .5em;
    }
      p {
        margin-bottom: 2em;
      }
    }
  }
  .x-outro {
    .bg{
      background: #bdf3fc;
    text-align: center;
    padding: 1em 5em 3em;
    margin: 0 2em;
    h2 {
      font-size: 2.5em;
      margin: 0;
      color: $blue;
      @include mobile() {
        font-size: 2em;
      }
    }
    img {
      width: 100px;
    }
    p {
      font-size: 1.1em;
      margin-top: 1em;
    }
    button {
      border-radius: 5px !important;
    }
    }
  }
}
