.x-navbar{
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.1);
    .x-notification{
        display: flex;
        .x-col{
            padding: 0.5em;
        }
        img{
            width: 50px;
            height: 50px;
        }
       
    }
    ul.desktop{
        display: flex;
        list-style-type: none;
        width: 95%;
        margin: auto;
        padding: 0;
        @include mobile(){
            padding: 1em;
        }
        li{
            &:first-child{
                // margin-right: auto;
                img{
                    width: 6em;
                }
            }
            &.notification{
                position: relative;
                margin-left: auto;
                a{
                    padding: 2.5em 1em;
                }
                .alert{
                    background: crimson;
                    color: #f7f7f7;
                    border-radius: 20px;
                    font-size: .8em;
                    padding: 0 4px;
                    position: absolute;
                    top: 20px;
                    right: 0px;
                }
            }
            &.profile{
                img{
                    width: 40px;
                    border-radius: 100%;
                    border: 2px solid $solidDash
                }
            }
            i{
                color: $solidDash;
            }
            a{
                padding: 2.5em 2em;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 1.2em;
                    border-bottom: 4px solid #f7f7f7;
                    color: #37517E;
                &:hover{
                    border-bottom: 4px solid $solidDash;
                    transition: 0.2s ease-in
                }
                &.active{
                    color: $blue;
                    font-family: Avenir-Bold;
                    border-bottom: 4px solid $solidDash;
                    transition: 0.2s ease-in
                }
            }
            @include mobile(){
                display: none;
                &:first-child{
                display: flex;
                justify-content: center;
                align-items: center;
                    img{
                        width: 100px;
                    }
                    a{
                        padding: 0;
                    }
                }
            }
        }

        @include mobile(){
            padding-right: 0;
        }
    }
    ul.x-mobile{
        display: flex;
        list-style-type: none;
        display: none;
        a{
        padding: 2em;

        }
      

        .notification{
            position: relative;
            margin-left: auto;
            a{
                padding: 2.5em 1em;
            }
            .alert{
                background: crimson;
                color: #f7f7f7;
                border-radius: 20px;
                font-size: .8em;
                padding: 0 4px;
                position: absolute;
                top: -15px;
                right: 0px;
            }
            .ui.top.right.pointing.dropdown>.menu{
                left: auto;
                width: 700%;
                overflow: hidden;
                left: -600%;
                min-width: none;
            }
        }
        @include mobile(){
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .ui.dropdown .menu>.item{
        &.unread{
            background: #f5f5f5 ;
        }
    }
}
.x-navbar2{
 
    // box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.1);
   
    .x-flex{
        display: flex;
        justify-content: space-between;
        @include mobile(){
            padding: 1em;
        }
        .x-notification{
            display: flex;
            .x-col{
                padding: 0.5em;
            }
            img{
                width: 50px;
            }
        }
        ul.desktop{
            display: flex;
            list-style-type: none;
            width: 95%;
            margin: auto;
            padding: 0;
           align-items: center;
           
            li{
                &:first-child{
                    margin-right: auto;
                    img{
                        width: 6em;
                    }
                }
                &.notification{
                    margin-left: auto;
                    a{
                        padding: 2.5em 1em;
                    }
                }
                &.profile{
                    img{
                        width: 40px;
                        border-radius: 100%;
                        border: 2px solid $solidDash
                    }
                }
                i{
                    color: $solidDash;
                }
                a{
                    padding: 2.5em 2em;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    font-size: 1.2em;
                        border-bottom: 4px solid #f7f7f7;
                        color: #37517E;
                    &:hover{
                        border-bottom: 4px solid $solidDash;
                        transition: 0.2s ease-in
                    }
                    &.active{
                        color: $blue;
                        font-family: Avenir-Bold;
                        border-bottom: 4px solid $solidDash;
                        transition: 0.2s ease-in
                    }
                }
                button{
                    margin: 0em 1em;
                    border-radius: 5px !important;
    
                }
    
                @include mobile(){
                    display: none;
                    &:first-child{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                        img{
                            width: 100px;
                        }
                        a{
                            padding: 0;
                        }
                    }
                }
            }
    
           
        }
        ul.x-mobile{
            display: flex;
            list-style-type: none;
            display: none;
            a{
            padding: 2em;
    
            }
            @include mobile(){
                display: flex;
                justify-content: center;
                align-items: center;
             
            }
        }
    }
   
   
}

.x-menulist{
    // display: flex;
    background: #f7f7f7;
    list-style-type: none;
    width: 100%;
    padding: 0 1em 1em;
    margin: 1em 0;
    box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.2);
    transition: 0.2s ease-in;
    &.open{
    transition: 0.2s ease-in;
    height: 150px;
    overflow: hidden;
    }
    &.closed{
    transition: 0.2s ease-in;
    height: 0;
    overflow: hidden;
    padding: 0 1em 0;
    // margin: 0;

    }
    li{
        a{
            padding: .5em 1em ;
            display: block;
        }
    }
}