.x-invoiceheet{
           .x-container{
        // background: #fff;
    }
    *{
        color: #333333 ;
    }
    .x-first{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2em;
        border-bottom: 1px solid #000000;
        font-size: 1.1em;

        h3{
            margin: 0;
        }
    }
    .x-second{
        padding:2em 1em;
        border-bottom: 1px solid #000000;
        font-size: 1.1em;
        .x-right{
            text-align: right;
        }
    }
    .x-third{
        padding:2em 1em;
        border-bottom: 1px solid #000000;
        font-size: 1.1em;
        .x-head{
            h3{
                font-size: 1.2em;
            }
        }
    }
    .x-fourth{
        padding:2em 1em;
        border-bottom: 1px solid #000000;
        font-size: 1.1em;
        .x-right{
            text-align: right;
        }
        h2{
            margin: 0;
            font-size: 2.9em;
        }
    }
    .x-pay{
        padding:2em 1em;
        text-align: right;

    }
}