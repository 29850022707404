
.imageUpload{
    background: #F8FCFF;
    border: 1px solid #E1F3FF;
    padding: 2em;
    .x-header{
        font-size: 1.2em;
        margin: .5em;
    }
    img{
        width: 100px;
    }
}
