@font-face{
    src: url('../fonts/avenir-medium.ttf');
    font-family: 'Avenir-Medium'
}
@font-face{
    src: url('../fonts/avenir-regular.ttf');
    font-family: 'Avenir-Regular'
}
@font-face{
    src: url('../fonts/avenir-bold.ttf');
    font-family: 'Avenir-Bold'
}