
.x-tourguide {
    width: 500px;
    padding: 2em;
    position: absolute;

    .x-tour{
    background-size: auto;
    background-position: 100%;
    height: -webkit-max-content;
    height: max-content;
    position: absolute;
    width: 100%;
    z-index: 1020;
    padding: 6em 5em 2em;
    margin-top: -3em;
    
    }
    .x-tour.x-left {
        background: url(https://res.cloudinary.com/dzvhmiutm/image/upload/v1664527244/b4a/Left_union_hrphio.png) no-repeat;
        margin-left: -4em;
    }
    .x-tour.x-right {
        background: url(https://res.cloudinary.com/dzvhmiutm/image/upload/v1664527244/b4a/Right_union_iknreo.png) no-repeat;
        margin-left: -27em;
    }
    
}