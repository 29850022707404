.x-payment {
  padding: 2em 0;
  section {
    .x-pagination {
      font-size: 1.2em;
      color: #828282;
    }
    .x-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #eee;
      padding: 2em 0;
    }
    .x-title {
      font-size: 2.2em;
      color: $blue;
      text-transform: capitalize;
    }
    .x-project {
      font-size: 1.2em;
      margin: 1em 0;
    }
  }

//   .x-first-sec {
//     display: flex;
//     padding: 1em 0;
//     justify-content: space-between;
//     align-items: center;
//     border-bottom: 1px solid #eee;
 
//     .x-date {
//       color: #828282;
//     }
//   }
  .x-second {
    background: #fff;
    box-shadow: 0px 4px 15px rgba(173, 173, 173, 0.1);
    .x-back {
        padding: 2em 2em 0;
          color: #828282;
          span {
            font-size: 1.2em;
          }
    }
    .x-head {
      padding: 2em;
      border-bottom: 1px solid #e0e0e0;
      .x-left {
        h2 {
          margin: 0;
          color: #333;
          margin-bottom: 0.3em;
        }
        p {
          color: #828282;
          font-size: 1.2em;
        }
      }
      .x-right {
        .x-currency {
          border: 1px solid #37517e;
          border-radius: 19.5px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #333;
          padding: 0.4em;
          img{
              width: 20px;
              margin-right: 0.5em;
          }
          i {
            margin-top: -0.5em;
            // padding: 0;
          }
        }
      }
    }
    .x-body {

      padding: 2em;

    .x-methods{
        display: flex;
        border-bottom: 1px solid #B5E0FF;
        margin-bottom: 2em;
        .x-method{
            padding: 0 2em 2em;
            text-align: center;
          color: #828282;
            .x-img{
                min-height: 2.6em;
                img{
                    width: 25px;
                    opacity: 0.5;
                }
            }
        }
    }
      h2 {
        color: $blue;
      }
      .x-left{
        .flutterwave{
          background: #2a3362;
          color: #fff;
          justify-content: center;
          align-items: center;
          display: flex;
          img{
            width: 30px;
            margin-right: 15px;
          }

        }
       
        
      }
      .x-right {
        background: #f8fcff;
        border: 1px solid #b5e0ff;
        .x-top {
        padding: 2em;
        img {
            width: 15px;
            margin-right: 1em;
          }
          .x-landSize,
          .x-location {
            margin: 1.5em 0 0em;
            p {
              font-size: 1.1em;
            }
          }
        }
        .x-bottom{
            border-top: 1px solid #B5E0FF;
            padding: 1.5em 2em .5em 3.5em;
            font-size: 1.2em;
            .x-flex{
                margin-bottom: 1em;
            }
        }
        .x-total{
            background: #E1F3FF;
            padding: 1.5em 1em 1.5em 2.5em;
            margin: 0 1em 2em;
            font-size: 1.2em;
            .x-bold{
                color: $blue;
            text-transform: uppercase;

            }
        }
      }
    }
  }
}
