.x-invoice{
    padding: 2em 0;
    section{
        .x-pagination{
            font-size: 1.2em;
            color: #828282;
        }
        .x-flex{
    
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 2px solid #eee;
            padding: 2em 0;
    
        }
        .x-title{
            font-size: 2.2em;
            color: $blue;
            text-transform: capitalize;
        }
        .x-project{
            font-size: 1.2em;
            margin: 1em 0;
        }
    }
  
    .x-first-sec{
        display: flex;
        padding: 1em 0;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eee;
        .x-back span {
            font-size: 1.2em;
        }
        .x-date{
            color: #828282;
        }
    }
    .x-second{
        background: #fff;
       .x-head{
        border-bottom: 1px solid #B5E0FF;
        ul{
            display: flex;
            list-style-type: none;
            width: 100%;
            margin: 0;
            background: #E1F3FF;
            padding: 0 1em;
            li{
                padding:  1.5em;
                font-size: 1.2em;
                cursor: pointer;
                &:hover{
                    border-bottom: 4px solid $solidDash;
                    transition: 0.2s ease-in
                }
                &.active{
                    color: $blue;
                    font-family: Avenir-Bold;
                    border-bottom: 4px solid $solidDash;
                    transition: 0.2s ease-in
                }
            }
           }
       }
       .x-body{
           margin-top: 3em;
           padding: 1.5em  0;
           font-size: 1.1em;
           .x-left{
                padding: 1em 2em ;
                text-transform: capitalize;
                img{
                    width: 12px;
                    margin-right: 0.5em;
                }
            }
           .x-box{
            background: #F8FCFF;
            p{
                padding: 1.5em 2em 0;
                font-size: 1.1em;
            }
            .x-desc{
                padding: 0 2em;
                margin-bottom: 1em;
            }
           }
       }
    }
}