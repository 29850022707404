.x-dashboard{
    padding: 2em 0;
    @include mobile(){
        padding: 0;
    }
    section{
        .x-pagination{
            font-size: 1.2em;
            color: #828282;
            @include mobile(){
                font-size: 1.1em;
            }
        }
        .x-flex{
    
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 2px solid #eee;
            padding: 2em 0;
            @include mobile(){
              display: block;
            .ui.huge.button{
                padding: .8em .8em !important;
                font-size: .9em !important;
                text-transform: lowercase !important;
                margin-top: 1em;
            }
            }
    
        }
        .x-title{
            font-size: 2.2em;
            color: $blue;
            text-transform: capitalize;
            @include mobile(){
                font-size: 1.2em;
            }
        }
        .x-project{
            font-size: 1.2em;
            margin: 1em 0;
            @include mobile(){
                margin: 0;
                font-size: 1em;
            }
        }
    }
    .x-tabs {
        background: #fff;
        .x-head {
          border-bottom: 1px solid #b5e0ff;
          ul {
            display: flex;
            list-style-type: none;
            width: 100%;
            margin: 0;
            background: #e1f3ff;
            padding: 0 1em;
            li {
              padding: 1em 2em;
              font-size: 1.2em;
              cursor: pointer;
             
              &:hover {
                border-bottom: 4px solid $solidDash;
                transition: 0.2s ease-in;
              }
              &.active {
                color: $blue;
                font-family: Avenir-Bold;
                border-bottom: 4px solid $solidDash;
                transition: 0.2s ease-in;
              }
            }
          }
        }
        .x-body {
          margin-top: 3em;
          padding: em 0;
          font-size: 1.1em;
          .ui.table{
              border: none !important;
          }
          .x-left {
            padding: 1em 2em;
            text-transform: capitalize;
            img {
              width: 12px;
              margin-right: 0.5em;
            }
            button {
              border-radius: 20px !important;
              background: #00b9ff !important;
              i {
                color: #fff !important;
              }
            }
          }
          .x-box {
            background: #f8fcff;
            p {
              padding: 1.5em 2em 0;
              font-size: 1.1em;
            }
            .x-desc {
              padding: 0 2em;
              margin-bottom: 1em;
            }
          }
        }
      }
    .left-box{
        background: url('../../assets//building.png') no-repeat;
        background-size: cover;
        padding: 2em 3em;
        h2{
            font-family: Avenir-Bold;
            color: $blue;
            font-size: 2em;

        }
        button{
            background: white;
            color: $blue;
            letter-spacing: unset !important;
            font-family: Avenir-Medium !important;
            padding-bottom: 1.5em;
            padding-top: 1.5em;
        }
    }
    .right-box{
        background: linear-gradient(rgba(0, 183, 255, 0.9),rgba(0, 183, 255, 0.9)), url('../../assets/vector.png') no-repeat;
        background-position: right;
        background-size: 40%;
        padding: 2em;
        color: #fff;
        img{
            width: 1.2em;
        }
        h2{
            font-size: 1.5em;
            font-family: Avenir-Bold;
            margin: 0
        }
    
        h3{
            margin: 0;
            font-size: 1.1em;
            font-family: Avenir-Medium;
        }
        p{
            margin: 1em 0 0;
        }
    }

    .x-box{
        padding:  3em 3.5em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h2{
            font-family: Avenir-Bold;
        }
        img{
            width: 1.4em;
        }
        a{
            margin-top: 2em !important;
            text-decoration: underline;
            color: #000;
        }
        &.explore{
            background: #FEEAEA;
            ;
        }
        &.mortgage{
            background: #E4EDFF

        }
        &.insurance{
            background: #EDE4FE
            ;
        }
        &.refer{
            background: #E3FFEE;
        }
    }
}