.x-upload-receipt{
    .header{
       border-bottom: unset !important;
       .x-back{
          font-size: 0.8em !important;
          color: #828282;
          i{
              font-size: 0.8em;
          }
       }
    }
    .imageUpload{
        background: unset;
        border: unset;
    }
   .x-content{
    // justify-content: center;
    // align-items: center;
    // display: flex;
    text-align: center;
    padding: 0 0em 2em;
    &.edit-doc{
    padding: 0 2em 2em;

    }
    a{
        font-family: Avenir-Medium;
        text-decoration: underline;
        display: block;
    }
    .x-url{
      margin-bottom: 1em;
    }
   }
   .ui.selection.dropdown,  .ui.form textarea{
       font-size: 1.5em;
   }
} 