.x-projectcard{
    .ui.table thead th{
        background: #fff;
        padding: 2em;
        font-family: Avenir-Medium;

    }
    .ui.table .row{
        cursor: pointer !important;
    }
    .ui.striped.table tbody tr:nth-child(2n), .ui.striped.table>tr:nth-child(2n){
        background: #F8FCFF;
    }
    .ui.table td{
        padding-top: 2em;
        padding-bottom: 2em;
        button{
            border-radius: 20px !important;
        }
        .menu{
            position: relative !important
        }
    }
}
.x-projectcard2{
    .x-card{
        background: #fff;

        .x-topcard{
            padding: 2em 1.8em;

            h2{
                color: $blue;
                margin: 0;
                font-size: 1.5em;
            }
            i{
                color: $blue;
            }
            img{
                width: 15px;
                margin-right: 1em;
            }
            .x-landSize, .x-location, .x-tour{
                margin: 1.5em 0 0em;
                p{
                    font-size: 1.1em;
                }
                button{
                    border-radius: 20px !important;
                    background: #00B9FF
                    !important;
                    i{
                        color: #fff !important
                    }
                }
            }
        }
        .x-boxcard{
            background: #F8FCFF;
            border: 1px solid #B5E0FF;
            margin: 0 0.5em 0em;
    
            .x-stats{
                border-bottom: 0.5px solid #B5E0FF;
                padding: 1em 1.5em;
                align-items: center;
                .title{
                    font-size: 1.2em;
                    font-family: Avenir-Medium;
                }
                button{
                    border-radius: 20px !important;
                }
                }
            .x-projectStatusDesc{
                padding: 2em 0.5em;
            }
        }
    }

}