.x-footer{
    display: flex;
    justify-content: space-between;
    @include mobile(){
        display: none;
    }
    .x-notification{
        display: flex;
        .x-col{
            padding: 0.5em;
        }
        img{
            width: 50px;
        }
    }
    ul.desktop{
        display: flex;
        list-style-type: none;
        width: 95%;
        margin: auto;
        padding: 0;
       
        li{
            &:first-child{
                margin-right: auto;
                img{
                    width: 6em;
                }
            }
            &.notification{
                margin-left: auto;
                a{
                    padding: 2.5em 1em;
                }
            }
            &.profile{
                img{
                    width: 40px;
                    border-radius: 100%;
                    border: 2px solid $solidDash
                }
            }
            i{
                color: $solidDash;
            }
            a{
                padding: 2.5em 2em;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 1.2em;
                    border-bottom: 4px solid #f7f7f7;
                    color: #37517E;
             
            }
            @include mobile(){
                display: none;
                &:first-child{
                display: flex;
                justify-content: center;
                align-items: center;
                    img{
                        width: 3em;
                    }
                    a{
                        padding: 0;
                    }
                }
            }
        }

        @include mobile(){
            padding-right: 0;
        }
    }
    ul.x-mobile{
        display: flex;
        list-style-type: none;
        display: none;
        a{
        padding: 2em;

        }
        @include mobile(){
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}