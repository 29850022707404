.x-pano{
    .x-view{
        display: flex;
        .x-ground{
            width: 100%;
            // height: 50vh;
            display: block;
        }
    }
    .x-media{
        display: flex;
        align-items: center;
        justify-content: center;
        background: #222;
       
          video{
            // width: 50vh;
            height: 50vh;
        }
        .x-image-media{
                width: 100%;
                .x-image-view{
                    display: flex;
                    width: 100%;

                }
                .x-image-view > div{
                    width: 100%;
                }
                .x-transformCon{
                    width: 100%;
                }
                .x-transformCom{
                    height: 50vh;
                    cursor: pointer;
                    width: 100%;
                    img{
                        width: 100%;
                    }
                    
                }
                .x-tools{
                    position: absolute;
                    top:   40vh;
                    left:  90vw;
                    align-self: center;
                    // margin: 1em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                   
                    .x-zoom{
                        align-self: center;
                        margin: .1em;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: rgba($color: #000000, $alpha: .4);
                    color: #ddd;
                    font-size: 1em;
                    border-radius: 100%;
                    width: 40px;
                    height: 40px;
                    cursor: pointer;
                    transition: 0.2s ease-in;
                    z-index: 10000;
                    i{
                        margin: 0;
                    }
                    // &:hover{
                    //     width: 80px;
                    //     margin: 0;
                    // height: 80px;
                    // transition: 0.2s ease-in;
                    // font-size: 1.3em;
                    // }
                    // @include mobile(){
                    //     width: 40px;
                    //     height: 40px;
                    //     font-size: 1.1em;
                    // }
                    }
                }
        }
      
      
    }
    .x-filetype{
        position: absolute;
        right: 0;
        margin: 1em;
        .dropdown{
            min-width: 30px;
            padding-right: 10em;
        }
        @include mobile() {
            .dropdown {
                padding-right: 2em;
                font-size: .9em;
                top: 30px;
            }
        }
    }
    .x-timetravel{
        position: absolute;
        top: 0;
        right: 0;
        margin: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        .x-split{
            height: 38px;
            background: rgba($color: #000000, $alpha: .6);
            color: #fff;
            padding: .5em 2em;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            margin-right: .1em;
            text-align: center;
            cursor: pointer;
        transition: 0.2s ease-in;
        &:hover{
            padding: .5em 3em;
        transition: 0.2s ease-in;
        }
        }
        .dropdown{
            background: rgba($color: #000000, $alpha: .6);
            min-width: 30px;
            padding-right: 10em;
            color: #fff;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            @include mobile(){
                padding-right: 4em;
            }
        }
    }
    .x-timetravel2{
        position: absolute;
        top: 0;
        left: 30%;
        margin: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        .dropdown{
            background: rgba($color: #000000, $alpha: .6);
            // min-width: 30px;
            // padding-right: 10em;
            color: #fff;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }
    }
    .x-back , .x-upload{
        position: absolute;
        top: 0;
        left: 0;
        margin: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba($color: #000000, $alpha: .8);
        color: #fff;
        font-size: 1.2em;
        border-radius: 100%;
        width: 50px;
        height: 50px;
        cursor: pointer;
        transition: 0.2s ease-in;
        z-index: 10000;
        &:hover{
            width: 80px;
            margin: 0;
        height: 80px;
        transition: 0.2s ease-in;
        font-size: 1.3em;
        }
        @include mobile(){
            width: 40px;
            height: 40px;
            font-size: 1.1em;
        }
    }
    .x-upload{

        left: 60px;
    }
    .x-left{
        // background: #000;
        // position: absolute;
        // height: 100%;
        // width: 100%;
    }
    .x-right{
        background: #000;
        height: 200px;
    }
   .x-drawingtype {
        position: absolute;
        left: 0;
        padding: 0.5em 1em;
        z-index: 1;
        .dropdown {
            min-width: 30px;
            padding-right: 10em;
        }
        @include mobile(){
                right: 0;
                width: 100%;
                display: -webkit-flex;
                display: flex;
          .dropdown {
                padding-right: 2em;
                font-size: .9em;
                width: 50%;
            }
        }
    }
 
}