*{
    font-family: Avenir-Regular;
}
body{
    background: #F9F9F9;
}
button{
    font-weight: unset !important;
    border-radius: unset !important;
}
.x-bold{
    font-family: Avenir-Bold;
}
.x-medium{
    font-family: Avenir-Medium;
}

.ui.green{
    background-color: $success !important;
}
.x-container{
    width: 85%;
    margin: auto;
}
.animate-loader{
    position: fixed;
 display: flex;
 vertical-align: middle;
 justify-content: center;
 align-items: center;
 top:0;
 bottom: 0;
 width: 100%;
 z-index: 4000;
 background: #333
}

.ui.grid{
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.ui.dimmer{
    position: fixed !important
}
.ui.basic.segment{
    padding: 0px;
}
.ui.floating.dropdown .menu.code{
    left:-125px !important;
}
.xcursor{
    cursor: pointer;
}
.no-padding{
    padding: 0px !important
}
.no-leftpad{
    padding-left: 0px !important
}
.no-rightpad{
    padding-right: 0px !important
}
.no-toppad{
    padding-top: 0px !important
}
.pad-bottom{
    padding-bottom: 20px
}
.flex-justify{
    display: flex !important;
    align-items: center !important
}
.flex-justify > div{
    flex-grow: 1
}
.x-space-between{
    justify-content: space-between;
}
.x-flex{
    display: flex;
}
.ui.huge.button, .ui.huge.buttons .button, .ui.huge.buttons .or{
    font-size: 1.2em !important;
    font-family: Avenir-Regular;
    letter-spacing: 0.1em;
}
.x-underline{
    text-decoration: underline;
}
.x-green{
    color: $success;
}
.ui.progress .bar{
    height: 0.2em;
    background-color:  #00B9FF !important;
}
.ui.progress{
    // margin: 1em 0;
    margin: 0 !important;

}
.ui.indicating.progress .bar, .ui.indicating.progress .bar{
    background: #fff;
}

.x-loader{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    svg{
        color: $blue;
    }
}

.x-page-loader{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 100%;
    svg{
        color: $blue;
    }
}