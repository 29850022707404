.x-countdown{
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
   
     .x-time{
        align-items: center;
        display: flex;
        color: #828282;
        .days, .hr,.min,.sec{
            font-size: 2.5em;
        font-family: Avenir-Bold;
        color: #00B9FF;
        }
        div{
            text-align: center;
        }
        span.slash{
            // margin-top: ;
            margin: -2em 1em 0;

        }
     }
}